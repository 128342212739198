import React from 'react';
import Text from '@rexlabs/text';
import { map } from 'shared/utils/text';

interface Props {
  regular?: boolean;
  undecorated?: boolean;
  grey?: boolean;
  href?: string;
  target?: string;
  semibold?: boolean;
}

const Link: React.FC<Props> = ({
  regular,
  undecorated,
  grey,
  semibold,
  ...props
}) => (
  <Text
    is='a'
    {...props}
    type={map({
      LINK: true,
      UNDECORATED: undecorated,
      REGULAR: regular,
      SEMIBOLD: semibold,
      GREY: grey
    })}
  />
);

export default Link;
