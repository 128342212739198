/*
|-------------------------------------------------------------------------------
| App Config Loader
|-------------------------------------------------------------------------------
|
| Exposes app environment variables, resolving dev variables when available.
|
| ```js
| import config from 'config'
| config.API_URL
| ```
|
*/

import userConfig from '../env.js';
import { merge } from 'lodash';

const isDev = process.env.NODE_ENV !== 'production';
// eslint-disable-next-line
console.log(`Config loading in ${isDev ? 'development' : 'production'} mode.`);

export default merge(
  {},
  // We use the release hash for cache busting, so to be sure we create a
  // fallback based on the current timestamp
  {
    RELEASE: { HASH: new Date().getTime().toString(), VERSION: '0.0.0-local.0' }
  },
  userConfig
);
